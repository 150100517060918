import { GoogleLogin } from './googleLogin.js'; // Newly added import
import { SoundBar } from './soundBar.js';
import { sendAmbianceMessage } from './socket-client.js';
import {initSearch} from "./search";

const globalStartTime = Date.now(); // Establish a shared start time

export const AmbianceSounds = {
    soundBars: [],
    currentAmbiances: {},
    audioContext: null,
    ambianceSounds: null,
    selectedContext: "All",
    presets: {}, // Stocker les presets

    getAudioContext() {
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        }
        return this.audioContext;
    },

    async loadAmbianceButtons() {
        let response;
        this.ambianceSounds = [];
        this.soundBars = [];
        if (GoogleLogin.userId) {
            response = await fetch(`/ambianceSounds?userId=${GoogleLogin.userId}`);
        } else {
            response = await fetch(`/ambianceSounds`);
        }
        this.ambianceSounds = await response.json();
        this.generateAmbientButtons(this.ambianceSounds);

        // Charger les presets après avoir généré les boutons
        if (GoogleLogin.isSignedIn) {
            await this.loadPresets();
        }

        initSearch('#searchInput', '.searchable');
        return this.ambianceSounds;
    },

    async resetAmbientSounds() {
        this.soundBars.forEach(soundbar => {
            soundbar.setVolume(0);
            soundbar.progressBar.style.width = 0 + '%';
        });
    },

    generateAmbientButtons(ambianceSounds) {
        const section = document.getElementById("ambiance");
        section.innerHTML = `
            <!-- Section title -->
            <div class="section-title absolute top-0 left-0">
                ambiance
                <button id="editAmbiance" class="buttonOutlinedLight" onclick="openEditPanel('ambiance')">
                    Edit
                </button>
            </div>
        `; // Clear existing content

        ambianceSounds.forEach(ambianceSound => {
             if (ambianceSound.visible) {
                const container = document.createElement('div');
                container.classList.add('sound-container', 'searchable', 'card');
                container.setAttribute('data-name', ambianceSound.display_name); // Ajoute data-name avec le nom
                // Create a div for the sound bar
                const soundBarDiv = document.createElement('div');
                soundBarDiv.id = `sound-bar-${ambianceSound.filename}`; // Assign a unique ID
                soundBarDiv.className = 'sound-bar'; // Apply style

                container.appendChild(soundBarDiv);
                const soundBar = new SoundBar(ambianceSound);

                soundBar.onVolumeChange = (volume) => {

                    const animationDuration = 2000; // 2-second animation in milliseconds
                    const elapsedTime = (Date.now() - globalStartTime); // Calculate position in animation cycle
                    const delay = `-${elapsedTime % animationDuration}ms`; // Convert to seconds with a negative offset

                    if (volume > 0) {
                        soundBar.progressBarContainer.style.animationDelay = delay; // Dynamically adjust delay
                        soundBar.progressBarContainer.classList.add('shadow-neon', 'animate-neon-glow');
                    } else {
                        soundBar.progressBarContainer.classList.remove('shadow-neon', 'animate-neon-glow');
                        soundBar.progressBarContainer.style.removeProperty('--animation-delay');
                    }
                };

                this.soundBars.push(soundBar);
                container.appendChild(soundBar.getElement());
                section.appendChild(container);
            }
        });

        // Ajouter les contrôles de preset si l'utilisateur est connecté
        if (GoogleLogin.isSignedIn) {
            this.addPresetControls();
        }
    },

    async savePreset() {
        const presetNameInput = document.getElementById('presetName');
        const presetName = presetNameInput.value.trim();
        if (presetName === '') {
            alert('Veuillez entrer un nom pour le preset.');
            return;
        }

        // Récupérer les niveaux de volume actuels
        const presetData = {};
        console.log(this.soundBars)

        this.soundBars.forEach(soundBar => {
            presetData[soundBar.ambianceSound.filename] = soundBar.getVolume();
        });

        // Sauvegarder le preset localement
        this.presets[presetName] = presetData;

        // Envoyer le preset au serveur
        if (GoogleLogin.userId) {
            const dataToSend = {
                userId: GoogleLogin.userId,
                presetName: presetName,
                presetData: presetData,
            };

            try {
                const response = await fetch('/save-preset', {
                    method: 'POST', // Méthode HTTP POST pour envoyer les données au serveur
                    headers: {
                        'Content-Type': 'application/json', // Type de contenu JSON
                    },
                    body: JSON.stringify(dataToSend), // Convertir les données en chaîne JSON
                });

                if (response.ok) {
                    console.log('Preset sauvegardé sur le serveur avec succès');
                    // Recharger les presets depuis le serveur pour s'assurer qu'ils sont à jour
                    await this.loadPresets();
                } else {
                    console.error('Erreur lors de la sauvegarde du preset sur le serveur');
                }
            } catch (error) {
                console.error('Erreur réseau :', error);
            }
        } else {
            console.error('User ID is not available'); // Gérer les cas où l'ID utilisateur est manquant
        }

        // Mettre à jour la boîte déroulante
        this.generatePresetsButtons();

        // Réinitialiser le champ de saisie
        presetNameInput.value = '';
    },


    addPresetControls() {
        // Créez un bouton
        const addPresetButton = document.createElement("button");
        addPresetButton.id = "addPresetButton";
        addPresetButton.className = "buttonOutlinedLightRound my-auto h-11 w-11 flex items-center justify-center";

        // Créer le contenu SVG
        const svgIcon = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgIcon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        svgIcon.setAttribute("fill", "none");
        svgIcon.setAttribute("viewBox", "0 0 24 24");
        svgIcon.setAttribute("stroke-width", "1.5");
        svgIcon.setAttribute("stroke", "currentColor");
        svgIcon.classList.add("size-7");

        // Ajouter le chemin à l'élément SVG
        const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
        path.setAttribute("stroke-linecap", "round");
        path.setAttribute("stroke-linejoin", "round");
        path.setAttribute("d", "M12 4.5v15m7.5-7.5h-15");

        // Insérer le chemin dans le SVG
        svgIcon.appendChild(path);

        // Ajouter le SVG au bouton
        addPresetButton.appendChild(svgIcon);

        // (Optionnel) Ajouter le bouton à un élément parent dans le DOM
        document.body.appendChild(addPresetButton);

        // Ajoutez le bouton à la section ambiancePreset
        const ambiancePresetSection = document.getElementById("ambiancePreset");
        if (ambiancePresetSection) {
            ambiancePresetSection.appendChild(addPresetButton);
        } else {
            console.error("La section #ambiancePreset n'a pas été trouvée.");
        }

        const presetModal = document.getElementById("preset-modal");
        const closePresetModalButton = document.getElementById("close-preset-modal");
        const cancelPresetButton = document.getElementById("cancel-preset");

        // Fonction pour afficher la modale
        const showPresetModal = () => {
            if (presetModal) {
                presetModal.classList.remove("hidden");
            } else {
                console.error("La modale #preset-modal n'a pas été trouvée.");
            }
        };

        // Fonction pour masquer la modale
        const hidePresetModal = () => {
            if (presetModal) {
                presetModal.classList.add("hidden");
            }
        };

        // Ajout des gestionnaires d'événements
        if (addPresetButton) {
            addPresetButton.addEventListener("click", showPresetModal);
        }

        if (closePresetModalButton) {
            closePresetModalButton.addEventListener("click", hidePresetModal);
        }

        if (cancelPresetButton) {
            cancelPresetButton.addEventListener("click", hidePresetModal);
        }

        // Gestion du clic en dehors de la modale
        if (presetModal) {
            presetModal.addEventListener("click", (event) => {
                if (event.target === presetModal) {
                    hidePresetModal();
                }
            });
            // Ajout de l'écouteur d'événement sur l'ID preset-save
            const presetSaveButton = document.getElementById("save-preset");
            if (presetSaveButton) {
                presetSaveButton.addEventListener("click", this.savePreset.bind(this));
            }
        }

        //Update presets section title visibility
        let presetSectionTitle = document.getElementById('presetSectionTitle');
        presetSectionTitle.classList.toggle('hidden')

        //Update presets section visibility
        let presetSection = document.getElementById('ambiancePreset');
        presetSection.classList.toggle('!hidden')

        // Mettre à jour la boîte déroulante des presets
        this.generatePresetsButtons();
    },


    applyPreset(presetName) {
        if (presetName === '') return;
        const presetData = this.presets[presetName];
        if (!presetData) return;
    
        this.soundBars.forEach(soundBar => {
            const volume = presetData[soundBar.ambianceSound.filename] || 0;
            soundBar.setVolume(volume);
            soundBar.progressBar.style.width = (volume * 100) + '%';
        });
    
        // Send the current ambiance status via WebSocket
        const currentStatus = this.getCurrentAmbianceStatus();
        sendAmbianceMessage(currentStatus);
    },

    generatePresetsButtons() {
        const presetSection = document.getElementById('ambiancePreset');
        if (!presetSection) return;

        // Vérifiez si un conteneur existe déjà
        let presetContainer = document.getElementById('presetButtonContainer');
        if (!presetContainer) {
            // Si le conteneur n'existe pas, créez-le
            presetContainer = document.createElement('div');
            presetContainer.id = 'presetButtonContainer';
            presetContainer.className = 'col-span-5 order-1 preset-button-container flex flex-wrap gap-4 px-4';
            presetSection.appendChild(presetContainer);
        }
        presetContainer.innerHTML = ''

        // N'afficher les presets que si l'utilisateur est connecté
        if (GoogleLogin.isSignedIn) {
            // Videz le conteneur pour éviter la duplication des boutons
            presetContainer.innerHTML = '';

            Object.keys(this.presets).forEach(presetName => {
                // Création d'un bouton pour chaque preset
                const button = document.createElement('button');
                button.textContent = presetName;
                button.className = 'preset-button px-4 py-2 searchable bg-blue-500 text-white rounded hover:bg-blue-600 max-w-28';
                button.setAttribute('data-name', presetName);
                // Ajout d'un événement de clic pour chaque bouton
                button.addEventListener('click', () => {
                    this.applyPreset(presetName); // Méthode fictive pour appliquer le preset
                });

                // Ajout du bouton dans le conteneur
                presetContainer.appendChild(button);
            });
        }
    },

    async loadPresets() {
        // Charger les presets depuis le serveur si l'utilisateur est connecté
        if (GoogleLogin.userId) {
            try {
                const response = await fetch(`/load-presets?userId=${GoogleLogin.userId}`);
                const data = await response.json();
                this.presets = data.presets || {};
                this.generatePresetsButtons();
            } catch (error) {
                console.error('Erreur lors du chargement des presets depuis le serveur :', error);
                this.presets = {};
            }
        } else {
            console.error('User ID is not available');
            this.presets = {};
        }
    },

    getCurrentAmbianceStatus() {
        const status = {};
        this.soundBars.forEach(soundBar => {
            status[soundBar.ambianceSound.filename] = soundBar.getVolume();
        });
        return status;
    },
};




