import Fuse from "fuse.js";

export function initSearch(inputSelector, itemSelector) {
    // Select the input element for the search
    console.log('Init search');
    const searchInput = document.querySelector(inputSelector);
    if (!searchInput) {
        console.error(`Input with selector "${inputSelector}" not found.`);
        return;
    }

    searchInput.value = ''

    // Select all searchable items
    const searchableItems = document.querySelectorAll(itemSelector);
    if (searchableItems.length === 0) {
        console.error(`No elements found with selector "${itemSelector}".`);
        return;
    }

    // Prepare the data for Fuse.js
    const fuseData = Array.from(searchableItems).map((item) => ({
        element: item,
        name: item.getAttribute('data-name'),
    }));

    // Initialize Fuse.js with options
    const fuse = new Fuse(fuseData, {
        keys: ['name'], // Search by 'name' key
        threshold: 0.4, // Fuzziness: 0 (exact match) to 1 (match anything)
    });

    let debounceTimeout; // Timeout ID for debouncing

    // Add an "input" event listener to filter dynamically with debounce
    searchInput.addEventListener('input', () => {
        clearTimeout(debounceTimeout); // Clear any existing timeout

        debounceTimeout = setTimeout(() => {
            const query = searchInput.value.trim().toLowerCase();

            if (!query) {
                // If query is empty, show all items
                searchableItems.forEach((item) => {
                    item.style.display = 'block';
                    item.classList.remove('animate-fade-out');
                    item.classList.add('animate-fade-in');
                });
                return;
            }

            const matches = fuse.search(query);

            console.log('Matches:', matches);
            // Handle item display based on search results
            searchableItems.forEach((item) => {
                const match = matches.some((result) => result.item.element === item);

                if (match) {
                    // Show matching items with fade-in animation
                    if (item.style.display === 'none' || item.classList.contains('fade-out')) {
                        item.style.display = 'block';
                        item.classList.remove('animate-fade-out');
                        item.classList.add('animate-fade-in');
                    }
                } else {
                    // Hide non-matching items with fade-out animation
                    if (item.style.display !== 'none') {
                        item.classList.remove('animate-fade-in');
                        item.classList.add('animate-fade-out');
                        setTimeout(() => {
                            item.style.display = 'none';
                        }, 300); // Delay corresponding to animation duration
                    }
                }
            });
        }, 300); // Delay of 300ms
    });
}

document.addEventListener('DOMContentLoaded', () => {
    const searchInput = document.getElementById('searchInput');
    const clearBtn = document.getElementById('clearSearchBtn');

    // Ensure both searchInput and clearBtn exist
    if (searchInput && clearBtn) {

        // Hide the clear button initially
        clearBtn.style.display = 'none';

        // Show/hide the clear button based on input
        searchInput.addEventListener('input', () => {
            if (searchInput.value.length > 0) {
                clearBtn.style.display = 'inline';
            } else {
                clearBtn.style.display = 'none';
            }
        });

        // Clear the input when the button is clicked
        clearBtn.addEventListener('click', () => {
            searchInput.value = '';
            clearBtn.style.display = 'none';
            searchInput.focus(); // Refocus on the input field
            // Trigger the 'input' event to ensure the search logic is called
            searchInput.dispatchEvent(new Event('input'));
        });
    }
});
