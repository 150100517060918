export const GoogleLogin = {
    CLIENT_ID: '297963915000-koosteg51bd8m6quhae71nugoqth4lmo.apps.googleusercontent.com',
    isSignedIn: false,
    Token: "",
    userId: null, // To store the unique user ID

    init() {
        if (typeof google === 'undefined') {
            console.error('Google Identity Services non chargé.');
            return;
        }
        this.restoreSession();

        google.accounts.id.initialize({
            client_id: this.CLIENT_ID,
            callback: this.handleCredentialResponse.bind(this),
        });

        google.accounts.id.renderButton(
            document.getElementById('google-login-button'),
            { theme: 'outline', size: 'medium' }
        );
    },

    handleCredentialResponse(response) {
        console.log("✅ Google Sign-In response:", response);

        this.isSignedIn = true;
        const payload = JSON.parse(atob(response.credential.split('.')[1]));
        this.userId = payload.sub;
        this.saveSession();

        console.log("📢 Dispatching userLoggedIn event...");
        setTimeout(() => {
            document.dispatchEvent(new CustomEvent('userLoggedIn', { detail: { userId: this.userId } }));
        }, 0);
    },

    handleLogout() {
        this.isSignedIn = false;
        this.userId = null;
        localStorage.removeItem('googleLoginState');

        document.dispatchEvent(new Event('userLoggedOut'));
    },

    saveSession() {
        localStorage.setItem('googleLoginState', JSON.stringify({ isSignedIn: this.isSignedIn, userId: this.userId }));
    },

    restoreSession() {
        console.log("📢 Dispatching userLoggedIn event from restore session...");
        const sessionData = localStorage.getItem('googleLoginState');
        if (sessionData) {
            const { isSignedIn, userId } = JSON.parse(sessionData);
            this.isSignedIn = isSignedIn;
            this.userId = userId;
            document.dispatchEvent(new CustomEvent('userLoggedIn', { detail: { userId: this.userId } }));
        }
    }
};